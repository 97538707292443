import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from "gatsby"

const HomeTop = ({headSource, bookImage}) => {
    
    return (

        <section className="HomeTop">
            <Img 
                fixed={headSource}
                alt={'Book in library'}
                objectFit="cover"
                objectPosition="50% 50%"
                style={{
                        width:'100%',
                        display:'block'
                    }}              
                />

            <div className="HomeTop-overlay">
                <div className="inner">
                    <h3>The writing and publishing website of Terry Carson</h3>
                   <div className="HomeTop-overlayFlex">
                        <div className="HomeTop-bookImage">
                        <Img 
                            fluid={bookImage.fluid}
                            alt={'Book'}
                            style={{
                                    width:'100%',
                                    display:'block'
                                }}
    
                        />
                        </div>
                        <div className="HomeTop-contentContainer">
                        <h1>Sergeant Kelly's Elephant</h1>
                         <p><span style={{color:'#FF0000'}}>NEW*</span> After surviving an assassination in Sydney, HRH Prince Alfred, with pet baby elephant, Tom, in tow, arrives in New Zealand in 1870. The authorities are on high alert as there are Fenian troubles in the goldfields. Aging Armed Constabulary Sergeant Patrick Kelly, who is out of favour with his superiors, is told his job is to protect the baby elephant. But when the Fenians come calling Kelly finds he is in the thick of the action, and there is a target on his back too.</p>
                            <Link to="/buy">Buy Now</Link>
                        </div>
                   </div>
                </div>
                
            </div>
        </section>
       
    )

}

export default HomeTop