import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'

const FromTheAuthor = ({authorImage}) => {
   
    return (

        <section className="FromTheAuthor">
            <h1>A Word from the <span className="red">Author</span></h1>
            <div className="inner">
                <blockquote className="FromTheAuthor-quote typography">
                    <p>Terry Carson gave up a legal career to write and finds his former career melds nicely with his interest in history - and historical crime in particular. After non-fiction writing about old courthouse buildings, New Zealand’s most sensational early multiple murders (The Axeman’s Accomplice), and many articles for historical magazines, he is now concentrating on writing fiction set in nineteenth century New Zealand. When not engaged in writing, he enjoys e-biking in the nearby South Auckland countryside.</p>
                </blockquote>

                <div className="FromTheAuthor-image">
                    <Img 
                            fixed={authorImage.fixed}
                            alt={'Terry Carson'}
                            style={{
                                    display:'block',

                                }}              
                            />
                </div>
            </div>
           
        </section>
    )

}

export default FromTheAuthor