import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from "gatsby"


const TopSellers = ({data}) => {

    return (
        <section className="TopSellers">
            <h1>Current <span className="red">Books</span></h1>
            <div className="inner">
                {data.edges.map((e, i) => 
                    <Link to={`/current-books#${e.node.frontmatter.anchor}`} className="TopSellers-book" key={i}>
                        <div className="TopSellers-bookImage">
                            <Img 
                                fixed={e.node.frontmatter.image.bookImage.childImageSharp.fixed}
                                alt={'Book in library'}
                                objectFit="cover"
                                objectPosition="50% 50%"
                                style={{
                                        width:'100%',
                                        display:'block',

                                    }}              
                            />
                        </div>
                        
                        <h3>{e.node.frontmatter.title}</h3>
                    </Link>
                    // 
                )}
            </div>
            
        </section>
       
    )

}

export default TopSellers