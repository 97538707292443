import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeTop from "../components/hometop"
import TopSellers from "../components/topsellers"
import FromTheAuthor from "../components/fromtheauthor"
import { StopPress } from "../components/stoppress"

const IndexPage = ({data}) => {
    
    const { imageSharp: image, allMarkdownRemark, authorImage, topBookImage } = data
    
    const headSource = [
        image.big,
        {
            ...image.medium,
            media: `(max-width: 62.5em) and (min-width: 40em)`
        },
        {
            ...image.small,
            media: `(max-width: 40em)`
        },
    ]

    return (
        <Layout pageClass='home-page'>
        <SEO title="Home" />

            <HomeTop headSource={headSource}  bookImage={topBookImage}/>
            <StopPress />
            <TopSellers data={allMarkdownRemark} />
            <FromTheAuthor authorImage={authorImage} />
        </Layout>
    )
} 

export const pageQuery = graphql`
     {
        imageSharp(resolutions: {originalName: {eq: "books-library.jpg"}}) { 
            big: fixed (width: 2000, height: 590, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
            }
            medium: fixed (width: 1000, height: 590, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
            }
            small: fixed (width: 480, height: 590, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
            }
        
        }
        topBookImage: imageSharp(resolutions: {originalName: {eq: "sergeant-kellys-elephant-resize.jpg"}}) { 
            fluid (maxWidth: 220, maxHeight: 310, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
            }
          
        }
        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___sortOrder]}, limit: 4) {
            edges {
              node {
                frontmatter {               
                  title
                  anchor
                  image {
                    altText
                    bookImage {
                        childImageSharp {
                            fixed (width: 220, height: 310, quality: 100, cropFocus: CENTER) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                  }
                }
             
              }
            }
        }
        authorImage: imageSharp (resolutions: {originalName: {eq: "terry-carson.png"}}) { 
            fixed (width: 192, height: 199, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
            }
          
        
        }
    }
`

export default IndexPage
